*,
*:before,
*:after{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.cards-usuarios {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
}
  
input[type="radio"]{
    -webkit-appearance: none;
    appearance: none;
    /* background-color: white; */
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: absolute;
    box-shadow: 7px 7px 15px rgba(2,28,53,0.08);
    cursor: pointer;
    outline: none;
    z-index: 1000;
    overflow: hidden;
    transition: all ease-in-out .3s;
}
.cards-usuarios input[type="radio"]{
    height: 100px !important;
}

.label{
    position: absolute;
    margin: 20px;
    cursor: pointer;
}

span{
    font-weight: 400;
    font-size: 18px;
    color: #7b7b93;
}
input[type="radio"]:hover{
    transform: scale(1.05);
}

input[type="radio"] ~ img.imagenusuario {
    transition: all ease-in-out .3s;
}
input[type="radio"]:hover ~ img.imagenusuario {
    opacity: 0.5;
    transform: scale(1.03);

}
/* input.usuario-asignado */
input[type="radio"]:checked {
    border: 5px solid #478bfb;
}
input[type="radio"]:checked:after{
    background-color: #478bfb;
}

img.imagenusuario {
    display: block;
    width: 100px;
    height: 100px;
    z-index: 100;
    border-radius: 50%;
}
.usuario-asignado {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
}

.userControls {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
}
img.imagenusuario-small {
    border-radius: 25px;
}
.username {
    text-align: center;
    font-size: 12px;
}
.badgeTareas {
    display: flex;
    justify-content: space-around;
    padding: 4px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    align-content: space-between;
    position: absolute;
    z-index: 9555;
}
/* input[type="radio"]:before{
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background-color: #f9fafd;
    border: 1px solid #e2e6f3;
    border-radius: 50%;
    top: 35px;
    right: 20px;
}
input[type="radio"]:after{
    content: "";
    position: absolute;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border-radius: 50%;
    top: 39.5px;
    right: 24.5px;
} */

